
$primary-light: rgb(218,201,201);
$primary-light-opaque: rgb(218,201,201, .24); 
$primary-med: #878a91;
// $primary-dark: #2e3035;
$primary-dark: #306b99;
$bg-dark: #2e3035;
$bg-light: #878a91;

$std-width: min(1200px, 90vw);
$std-height: calc(100vh - 80px);

$border-light: rgba(195,181,181,0.55);

$chn1: #0A0F32;
$chn2: #752C7D;
$chn3: #66418A;
$chn4: #4C66A2;
$chn5: #2B96C0;
$chn5-text-light: #267898;

/* Hide scrollbar for Chrome, Safari and Opera */
// *::-webkit-scrollbar {
//   display: none;
// }

/* Hide scrollbar for Internet explorer and Edge */
// * {
  // -ms-overflow-style: -ms-autohiding-scrollbar;
// }

// disabled an unnecessary 2nd horizontal scrollbar on datagrids
::ng-deep .datagrid-inner-wrapper {
  overflow-x: hidden !important;
}

ion-searchbar, :after, :before {
  box-sizing: border-box !important;
}
.element-grid {
  padding: 20px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.element-container {
  border: 1px solid var(--ion-border-color);
  border-radius: 8px;
  width: 100%;
  min-height: 300px;
  max-height: 50vh;
  &.no-style {
    border: none;
    border-radius: 0px;
  }
}
.card-container {
  width: 100%;
  min-height: 200px;
}
.el6 {
  grid-column: auto / span 6;
  @media (max-width: 680px) {

  }
}
.el5 {
  grid-column: auto / span 5;
}
.el4 {
  grid-column: auto / span 4;
}
.el3 {
  grid-column: auto / span 3;
}
.el2 {
  grid-column: auto / span 2;
}
.el1 {
  grid-column: auto / span 1;
}

.lg-element {
  grid-column: auto / span 4;
  max-height: 70vh;
  &.tall {
    max-height: 100vh;
  }
} 
.md-element {
  grid-column: auto / span 2;
  max-height: calc(50vh - 40px);
} 
.sm-element {
  grid-column: auto / span 1;
  max-height: calc(25vh - 40px);
}
.bordered {
  border: 2px solid $border-light;
  border-radius: 8px;
  padding: 10px;
}
.left-bordered {
  border-left: 2px solid $border-light;
}

.no-underline {
  .mat-form-field-underline {
    display: none !important;
  }
}
.primary-dark {
  color: $primary-dark !important;
}

.pub, .wos, .ieee, .cordis_publications {
  color: rgb(201,201,237);
}
.grant, .cordis_grants, .nih, .nsf, .sbir {
  color: rgb(53,231,53);
}
.pat, .dwpi, .epodocdb_family {
  color: rgb(206,118,55);
}
.ct, .clinicaltrials {
  color: orange;
}

.ngx-charts text {
  fill: $primary-light;
  &:hover {
    fill: $primary-dark;
  }
}

.data-cell {
  &.clickable {
    color: var(--link-text-color);
  }
}
.clickable {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

/**
ng-mat overrides
*/
/** change the height of button-toggle-groups */
.group-controls {
  .add-group, .add-clause, .mat-button-toggle-label-content {
    height: 24px;
    line-height: 24px !important;
  }
}
.view-toggle-group {
  .mat-button-toggle-label-content {
    height: 35px;
    line-height: 35px !important;
  }
}
    

.primary-light {
  &.mat-button-disabled {
    background-color: $primary-light !important;
  }
  .mat-focused .mat-form-field-label {
    color: $primary-light !important;
  }
  .mat-form-field-can-float {
    color: $primary-light !important;
  }
  .mat-form-field-underline {
    background-color: $primary-light !important;
  } 
  .mat-form-field-label .ng-star-inserted {
    color: $primary-light;
  }
  .mat-form-field-ripple {
    background-color: $primary-light !important;;
  }
  .mat-tab-link {
    color: $primary-light;
    &:hover {
      color: $primary-dark;
      background-color: $bg-light;
    }
  }
  .mat-tab-nav-bar, .mat-tab-header {
    border-bottom: 1px solid $primary-light-opaque;
  }
}

.slide-in-left {
  animation: slide-in-left 1s forwards;
  -webkit-animation: slide-in-left 1s forwards;
}

.slide-in-right {
  animation: slide-in-right 1s forwards;
  -webkit-animation: slide-in-right 1s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}
  
@keyframes slide-in-left {
  100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in-left {
  100% { -webkit-transform: translateX(0%); }
}

@keyframes slide-in-right {
  100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in-right {
  100% { -webkit-transform: translateX(0%); }
}
  
@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

@-webkit-keyframes slide-out {
  0% { -webkit-transform: translateX(0%); }
  100% { -webkit-transform: translateX(-100%); }
}

// hide the select all button for the outer table
// .clr-outer-table {
//   .datagrid-header {
//     .datagrid-select {
//       // display: none !important;
//     }
//     .clr-checkbox-wrapper {
//       display: none !important;
//       input {
//         display: none !important;
//       }
//     }
//   }
// }
// remove the padding-top from the clarity supercluster table
clr-datagrid {
  z-index: 4 !important;
}
.clr-sc-table {
  .datagrid {
    margin-top: 0px !important;
  }
  // hide the header for the inner tables
  .clr-inner-table {
    // .datagrid-header {
    //   .clr-checkbox-wrapper {
    //     display: none;
    //   }
    // }
    // vertical center align inner table cells
    .inner-row {
      .datagrid-cell {
        display: grid !important;
        align-items: center;
      }
    }
  }
}

.subnav-bar {
  .clr-form-control {
    padding-top: 5px;
    margin-top: 0px !important;
    height: 100% !important;
    .clr-control-container {
      height: 100% !important;
      .clr-combobox-wrapper {
        height: 100% !important;
        .clr-combobox-input-wrapper {
          height: 100% !important;
        }
        .clr-input {
          height: 50px !important;
          max-height: 100% !important;
          font-size: 16px;
        }
      }
    }
  }
}
.ng-tooltip {
  position: absolute;
  max-width: 150px;
  font-size: 14px;
  text-align: center;
  color: #f8f8f2;
  padding: 3px 8px;
  background: #282a36;
  border-radius: 4px;
  z-index: 100000;
  opacity: 0;
}
.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}
.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent black transparent;
}
.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent black;
}
.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent black transparent transparent;
}
.ng-tooltip-show {
  opacity: 1;
}

.loader-container {
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-background-color);
}

.loader-wrapper {
  height: 130px;
  width: 130px;
  display: grid;
  justify-content: center;
  align-items: center;
  border: 8px solid #f3f3f3;
  border-top: 8px solid $chn5;
  border-radius: 50%;
  animation: spin 2s cubic-bezier(0.1, 0.7, 1, 0.1) infinite reverse;
}

.loader-wrapper>img {
  height: 100px;
  width: 100px;
  animation: spin 1s linear infinite !important;
  filter: invert(1);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
body.dark {
  .loader-wrapper>img {
    filter: invert(0);
  }
}
$prefix: "--"; //Prefix string for custom CSS properties

//Merges a variable name with $prefix
@function custom-property-name($name) {
    @return $prefix + $name;
}
@mixin define-custom-property($name, $value) {
  #{custom-property-name($name)}: $value;
}
body {
  // Append pre-defined colors in $colors:

  // Append SASS variables which are desired to be accesible:
  @include define-custom-property('chn5', $chn5);
}