/* You can add global styles to this file, and also import other style files */

@use 'sass:map';
@use '@angular/material' as mat;
@import '~modern-normalize/modern-normalize.css'; // css reset
@import '~@cds/core/global.min.css'; // clarity global styles
@import '~@cds/city/css/bundles/default.min.css'; // load base font
// @import '~@aws-amplify/ui-angular/theme.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// @include mat.all-component-densities(-2);
// @include mat.form-field-density(-4);
@include mat.all-component-densities(minimum);
.mdc-text-field {
  // background-color: white;
}

$dark-theme: mat.define-dark-theme((
 color: (
   primary: mat.define-palette(mat.$pink-palette, 700, 500, 900),
   accent: mat.define-palette(mat.$blue-grey-palette, A200, A100, A400)
 ),
));

body.dark {
  @include mat.core-color($dark-theme);
  @include mat.all-component-colors($dark-theme);
}

.source-code {
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-style: normal;
  src: url('/assets/SourceCodePro-VariableFont_wght.ttf');
}
.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  src: url('/assets/Montserrat-VariableFont_wght.ttf');
}
// .roboto {
//   font-family: "Roboto", sans-serif;
//   font-optical-sizing: auto;
//   font-style: normal;
//   src: url('/assets/Roboto-Black.ttf');
// }

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}
